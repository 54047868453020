/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Transition from "../components/transitions";
import { Helmet } from "react-helmet";
import "../theme/css/layout.css";
import "../theme/css/animations.css";
import "../theme/css/animations.scss";
import { ContactSection } from "../components/Sections";
import AOS from "aos";
import "aos/dist/aos.css";

const GlobalWrapper = styled.div`
  margin: 0 auto;
`;

const Layout = ({ children, location }) => {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
    
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 300, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1200, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    
    });
    AOS.refresh();
  }, []);
  // light={location.pathname !== '/'}
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <GlobalWrapper>
          <Helmet>
            <link rel="stylesheet" href="https://use.typekit.net/pbg1oiy.css" />
          </Helmet>
          <NavBar light={true} siteTitle={data.site.siteMetadata.title} />
          <div>
            <Transition location={location}>{children}</Transition>
            <ContactSection />
            <Footer />
          </div>
        </GlobalWrapper>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

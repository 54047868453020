import React from "react";
import styled from "styled-components";
import Header from "./Header";
import SubHeader from "./SubHeader";
import Body from "./Body";
import { Colors } from "../../theme/Colors";

const Wrapper = styled.div`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "32px"};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : null)};

  h5 {
    /* margin-bottom: -16px; */
  }
`;

const TextBlock = ({
  subheader,
  title1,
  title2,
  body,
  color,
  colorSwap,
  textAlign,
}) => {
  return (
    <Wrapper textAlign={textAlign}>
      {subheader && (
        <SubHeader
          data-aos="fadeInDown"
          data-aos-duration={400}
          data-aos-delay="75"
        >
          {" "}
          {subheader}
        </SubHeader>
      )}
      <Header
        data-aos="fadeInUp"
        data-aos-duration={400}
        data-aos-once="true"
        h2
        noMargin
        color={colorSwap ? null : color ? color : Colors.Purple}
      >
        {title1}
      </Header>
      {title2 && (
        <Header
          data-aos="fadeInUp"
          data-aos-duration={400}
          data-aos-once="true"
          h2
          noMargin
          color={color && colorSwap ? color : null}
        >
          {title2}
        </Header>
      )}
      {body && (
        <Body
          data-aos="fadeInUp"
          data-aos-duration={600}
          style={{ margin: "0 auto", paddingBottom: "30px" }}
          maxWidth={620}
        >
          {body}
        </Body>
      )}
      {/* <NewLink to={"/work/"}>{hero.buttonTitle}</NewLink> */}
    </Wrapper>
  );
};

export default TextBlock;
